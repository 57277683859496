/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import LineFull from '../../images/linefull.jpg';
import LocationRepeaters from './LocationsRepeater';

const LocationsList = data => {
    // eslint-disable-next-line no-unused-vars
    const [activeTab, setActiveTab] = useState('int');
    return (
        <section className="page-section centersec">
            <div className="container smallestwdt">
                <h4>
                    New We Rock the Spectrum locations will be opening soon
                    worldwide. All of our locations are independently owned and
                    operated. Membership cards are not transferable between any
                    of the gyms, under any conditions.
                </h4>
                <ul id="tabs">
					<li id="tab1" onClick={() => setActiveTab('int')}>
                        INTERNATIONAL LOCATIONS
                    </li>
                    <li id="tab3" onClick={() => setActiveTab('usa')}>
                        USA LOCATIONS
                    </li>
                    <li id="tab2" onClick={() => setActiveTab('usa-soon')}>
                        COMING SOON TO USA
                    </li>
                    
                    <li id="tab4" onClick={() => setActiveTab('int-soon')}>
                        INTERNATIONAL - SOON
                    </li>
                </ul>

                <img className="tabsline" src={LineFull} />
                <div className="tabcontent location_iframbox">
                    <LocationRepeaters
                        activeLoc={activeTab}
                        dataUsa={data.locationsUsa}
                        dataUsaCs={data.locationsUsaCs}
                        dataInt={data.locationsInt}
                        dataIntCs={data.locationsIntCs}
                    />
                </div>
            </div>
        </section>
    );
};

export default LocationsList;
